@font-face {
  font-family: 'Noway';
  font-weight: 'bold';
  src: url('./fonts/noway-bold-webfont.eot');
  src:
    url('./fonts/noway-bold-webfont.eot?#iefix') format('rembedded-opentype'),
    url('./fonts/noway-bold-webfont.woff') format('woff'),
    url('./fonts/noway-bold-webfont.ttf') format('truetype'),
    url('./fonts/noway-bold-webfont.svg#webfont') format('svg');
}

@font-face {
  font-family: 'Noway';
  font-weight: 'medium';
  src: url('./fonts/noway-medium-webfont.eot');
  src:
    url('./fonts/noway-medium-webfont.eot?#iefix') format('rembedded-opentype'),
    url('./fonts/noway-medium-webfont.woff') format('woff'),
    url('./fonts/noway-medium-webfont.ttf') format('truetype'),
    url('./fonts/noway-medium-webfont.svg#webfont') format('svg');
}

@font-face {
  font-family: 'Noway';
  font-weight: 'regular';
  src: url('./fonts/noway-regular-webfont.eot');
  src:
    url('./fonts/noway-regular-webfont.eot?#iefix') format('rembedded-opentype'),
    url('./fonts/noway-regular-webfont.woff') format('woff'),
    url('./fonts/noway-regular-webfont.ttf') format('truetype'),
    url('./fonts/noway-regular-webfont.svg#webfont') format('svg');
}

body {
  background-color: rgba(0, 51, 80, 1);
  color: #dfe9ef;
  font-family: 'Noway', 'Arial Narrow', sans-serif;
  text-align: center;
  line-height: 1.875rem;
}

h1 {
  font-size: 1.25rem;
  font-family: 'Noway', 'Arial Narrow', sans-serif;
  font-weight: normal;
  margin-top: 2em;
}

a {
  text-decoration: none;
  color: inherit;
  white-space: nowrap;
}

a.underline:hover,
a.underline:active {
  border-bottom: none;
  margin-bottom: 1px;
}

a.underline {
  display: inline-block;
  border-bottom: #dfe9ef solid 1px;
}

address {
  font-style: normal;
  word-spacing: 0.2rem;
}

ul {
  list-style: none;
  padding: 0;
  margin: 1rem 0 0;
}

.background-image {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  transition: opacity 2s;
}

.background-image.current {
  opacity: 1;
}

.background-image.hidden {
  opacity: 0;
}

#content {
  position: absolute;
  padding: 0 1rem;
  height: 100%;
  width: 100%;
  box-sizing: border-box;

  /* flexbox properties */
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
}

main {
  background-color: rgba(0, 51, 80, 0.93);
  padding: 1.5rem;
  max-width: 570px;

  /* flexbox properties */
  flex: 0 0 content;
}

main header {
  padding: 1rem 0 0;
}

footer {
  position: absolute;
  bottom: 0;
}

.info {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.services {
  font-size: 0.875rem;
  line-height: 1.5rem;
}

.services li {
  padding: 0 0.75rem;
  display: inline-block;
}

.services li::before {
  position: absolute;
  content: "•";
  margin-left: -1rem;
}

.services li.first-in-line::before {
  content: "";
}

.call-to-action {
  font-size: 1.125rem;
  margin: 2rem 0 0;
}

@media (max-width: 414px) {
  address a {
    display: block;
  }

  .or {
    display: none;
  }
}

@media (min-width: 1441px) {
  body {
    font-size: 22px;
  }

  main {
    max-width: 799px;
  }

  .services {
    max-width: 75%;
  }
}

@media (min-width: 900px) {
  body {
    font-size: 17px;
  }
}

@media (min-width: 415px) {
  header img {
    width: 70%;
  }
}

@media (max-height: 510px) and (orientation: landscape) {
  #content {
    padding: 0;
    height: 100vh;
    justify-content: stretch;
  }

  main {
    padding: 0;
    width: 110vh;
    flex-grow: 2;
  }

  ul {
    margin: 0;
  }

  .call-to-action {
    margin-top: 1.5rem;
  }

  footer {
    position: static;
    flex: 1 2 auto;
    background-color: rgba(0, 51, 80, 0.93);
    width: 110vh;
    font-size: 0.7rem;
  }
}

#imprint {
  background-color: #fff;
  color: #003350;
  padding: 1rem 2rem;
}

#imprint section {
  max-width: 799px;
  margin: auto;
}

#imprint a {
  font-size: 0.875rem;
}
